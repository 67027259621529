/* eslint-disable jsx-a11y/label-has-associated-control */
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import toast from 'react-hot-toast';
import { AiFillCaretDown } from 'react-icons/ai';
import InputMask from 'react-input-mask';
import MultipleSelect, { components } from 'react-select';
import * as Yup from 'yup';
import ConsentArea from '../../components/ConsentArea';
import FieldError from '../../components/FieldError';
import ResponsibleRadio from '../../components/ResponsibleRadio';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import Anchor from '../../components/ui/Anchor';
import Form, { Error, FormGroup, Input, Select, Textarea } from '../../components/ui/form';
import Label from '../../components/ui/form/label/label';
import Heading from '../../components/ui/Heading';
import SectionTitle from '../../components/ui/section-title';
import Text from '../../components/ui/Text';
import { Col, Row } from '../../components/ui/Wrapper';
import useFeedbackModal from '../../hooks/useFeedbackModal';
import useFormData from '../../hooks/useFormData';
import contactService from '../../services/contactService';
import consentTypes from '../../utils/constants/consentTypes';
import customerTypes from '../../utils/constants/customerTypes';

const schemaValues = {
  type: Yup.string()
    .matches(/PARENT|STUDENT/g)
    .required('Informe se você é o aluno ou seu responsável'),
  name: Yup.string().required('Campo obrigatório'),
  parent_name: Yup.string().when('type', {
    is: 'PARENT',
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string(),
  }),
  birthday: Yup.string()
    .matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/, 'Data inválida (dd/mm/aaaa)')
    .required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
  zip_code: Yup.string().required('Campo obrigatório'),
  knowledge_source_id: Yup.number().required('Campo obrigatório'),
  public_tender_ids: Yup.array().of(Yup.number()),
  school_unit_id: Yup.number().required('Campo obrigatório'),
  description: Yup.string()
    .test('len', 'A mensagem deve conter no máximo 1028 caracteres', (val = '') => val.length <= 1028)
    .required('Campo obrigatório'),
};

const schema = Yup.object().shape(schemaValues);

const consentValidationMessage = 'Você deve consentir com este item para continuar';

const initialValues = {
  type: undefined,
  name: '',
  parent_name: '',
  birthday: '',
  email: '',
  phone: '',
  zip_code: '',
  knowledge_source_id: undefined,
  public_tender_ids: [],
  school_unit_id: undefined,
  description: '',
};

const handleCloseFeedback = () => {
  window.location.reload();
};

const FaleConoscoForm = () => {
  const { units, knowledgeSources, isLoading, showLoading, hideLoading } = useFormData({ skipSegments: true });
  const [publicTenders, setPublicTenders] = useState([]);
  const [unitInfo, setUnitInfo] = useState(null);
  const [validationSchema, setValidationSchema] = useState(schema);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const publicTenderRef = useRef();
  const { FeedbackModal, openModal: openFeedbackModal } = useFeedbackModal({ onClose: handleCloseFeedback });

  const { handleSubmit, setFieldValue, getFieldProps, values, errors, touched, submitCount, resetForm } = useFormik({
    initialValues,
    validationSchema: useCallback(() => validationSchema, [validationSchema]),
    enableReinitialize: true,
    onSubmit: async data => {
      showLoading();
      try {
        const reCaptchaToken = await executeRecaptcha('contact_form');

        await contactService.sendMessage(
          {
            ...data,
            birthday: format(parse(data.birthday, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
            consents: Object.entries(data.consents).map(([key, value]) => ({ code: key, answer: value })),
          },
          reCaptchaToken
        );

        openFeedbackModal();

        resetForm({});
        publicTenderRef.current.select.clearValue();
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Aconteceu um erro inesperado. Tente novamente mais tarde');
      } finally {
        hideLoading();
      }
    },
  });

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <AiFillCaretDown />
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: '#f8f8f8',
      borderWidth: 0,
      paddingLeft: 20,
      minHeight: 56,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      paddingRight: 10,
    }),
  };

  const loadPublicTenders = async () => {
    try {
      const { data } = await contactService.publicTenders();

      setPublicTenders(data.map(({ id, name, initials }) => ({ value: id, label: `${initials} - ${name}` })));
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelectPublicTenders = tenders => {
    const ids = (tenders || []).map(({ value }) => value);
    setFieldValue('public_tender_ids', ids);
  };

  const handleChangeUnit = ({ target: { value } }) => {
    const unit = units.find(({ id }) => id.toString() === value.toString());
    setUnitInfo(unit);
    setFieldValue('school_unit_id', value);
  };

  const handleChangeType = ({ target: { value: type } }) => {
    setFieldValue('type', type);
  };

  const handleLoadConsent = (purposes = []) => {
    const newSchema = Yup.object().shape({
      ...schemaValues,
      consents: Yup.object().shape(
        purposes.reduce(
          (acc, { code, required }) => ({
            ...acc,
            [code]: required
              ? Yup.bool().oneOf([true], consentValidationMessage).required(consentValidationMessage)
              : Yup.bool(),
          }),
          {}
        )
      ),
    });

    setValidationSchema(newSchema);
  };

  useEffect(() => {
    loadPublicTenders();
  }, []);

  const isParent = useMemo(() => values.type === customerTypes.PARENT, [values.type]);

  return (
    <>
      <FeedbackModal title="Mensagem enviada">
        <p>Sua mensagem foi enviada com sucesso. Em breve entraremos em contato</p>
      </FeedbackModal>
      <Form onSubmit={handleSubmit}>
        <SectionTitle align="left" title={` Fale conosco`} mb="40px" />

        <ResponsibleRadio onChange={handleChangeType} value={values.type} />
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="name" description={isParent ? 'Aluno' : null} required>
                Nome
              </Label>
              <Input {...getFieldProps('name')} type="text" name="name" id="name" placeholder="Informe o nome" />
              <FieldError name="name" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        {isParent ? (
          <Row>
            <Col lg={12}>
              <FormGroup mb="20px">
                <Label name="parent_name" description="Responsável" required>
                  Nome
                </Label>
                <Input
                  {...getFieldProps('parent_name')}
                  type="text"
                  name="parent_name"
                  id="parent_name"
                  placeholder="Informe o nome fo responsável"
                />
                <FieldError name="parent_name" errors={errors} touched={touched} submitCount={submitCount} />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="birthday" description={isParent ? 'Aluno' : null} required>
                Data de Nascimento
              </Label>
              <InputMask {...getFieldProps('birthday')} mask="99/99/9999" maskChar={null}>
                {inputProps => (
                  <Input
                    {...inputProps}
                    type="text"
                    name="birthday"
                    id="birthday"
                    placeholder="Informe a data de nascimento"
                  />
                )}
              </InputMask>
              <FieldError name="birthday" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="name" description={isParent ? 'Responsável' : null} required>
                E-mail
              </Label>
              <Input {...getFieldProps('email')} type="text" name="email" id="email" placeholder="Informe o e-mail" />
              <FieldError name="email" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="cell_phone" description={isParent ? 'Responsável' : null} required>
                Telefone
              </Label>
              <InputMask {...getFieldProps('phone')} mask="(99) 99999-9999" maskChar={null}>
                {inputProps => (
                  <Input {...inputProps} type="text" name="phone" id="phone" placeholder="Informe o telefone" />
                )}
              </InputMask>
              <FieldError name="phone" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="zip_code" required>
                CEP
              </Label>
              <InputMask {...getFieldProps('zip_code')} mask="99999-999" maskChar={null}>
                {inputProps => (
                  <Input {...inputProps} type="text" name="zip_code" id="zip_code" placeholder="Informe o CEP" />
                )}
              </InputMask>
              <FieldError name="zip_code" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="knowledge_source_id" required>
                Como nos conheceu?
              </Label>
              <Select {...getFieldProps('knowledge_source_id')} name="knowledge_source_id" id="knowledge_source_id">
                <option value="">Selecione uma opção</option>
                {knowledgeSources?.map(({ id, name }) => (
                  <option value={id}>{name}</option>
                ))}
              </Select>
              <FieldError name="knowledge_source_id" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="public_tender_ids" className="multi-select">
                Concurso(s) de interesse(s)
              </Label>
              <MultipleSelect
                placeholder="Selecione seus concursos de interesse"
                components={{ DropdownIndicator }}
                options={publicTenders}
                styles={customStyles}
                onChange={handleSelectPublicTenders}
                closeMenuOnSelect={false}
                ref={publicTenderRef}
                isMulti
              />
              <FieldError name="public_tender_ids" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="school_unit_id" required>
                Unidade
              </Label>
              <Select
                {...getFieldProps('school_unit_id')}
                name="school_unit_id"
                id="school_unit_id"
                onChange={handleChangeUnit}
              >
                <option value="">Selecione uma unidade</option>
                {units?.map(({ id, name }) => (
                  <option value={id}>{name}</option>
                ))}
              </Select>
              <FieldError name="school_unit_id" errors={errors} touched={touched} submitCount={submitCount} />
              {unitInfo?.formatted_address ? (
                <Text>
                  {unitInfo.formatted_address} (
                  <Anchor
                    color="secondary"
                    path={`https://www.google.com/maps/dir/Current+Location/${encodeURIComponent(
                      unitInfo.formatted_address
                    )}`}
                    target="_blank"
                  >
                    Rotas via Google Maps
                  </Anchor>
                  )
                </Text>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="description" description={isParent ? 'Aluno' : null} required>
                Mensagem
              </Label>
              <Textarea
                {...getFieldProps('description')}
                name="description"
                id="description"
                placeholder="Por favor, descreva o motivo de seu contato"
              />
              <FieldError name="description" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <ConsentArea
          type={consentTypes.CONTACT_US}
          onLoad={handleLoadConsent}
          getFieldProps={getFieldProps}
          errors={errors}
          touched={touched}
          submitCount={submitCount}
        />
        <Row>
          <Col lg={12}>
            <SubmitButton isLoading={isLoading} />
          </Col>
        </Row>
      </Form>
    </>
  );
};

FaleConoscoForm.propTypes = {};

FaleConoscoForm.defaultProps = {};

export default FaleConoscoForm;
