/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import React from 'react';
import Contact from '../../containers/ContactPage';

const ContactPage = ({ location, pageContext }) => <Contact pageContext={pageContext} location={location} />;

ContactPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default ContactPage;
