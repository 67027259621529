import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../ui/Wrapper';
import { FormGroup, Radio } from '../ui/form';
import Label from '../ui/form/label/label';
import { RadioContainer } from './styles';
import customerTypes from '../../utils/constants/customerTypes';

const ResponsibleRadio = ({ onChange }) => {
  return (
    <Row>
      <Col lg={6} sm={12}>
        <FormGroup mb="20px">
          <RadioContainer>
            <Radio id={customerTypes.PARENT} name="type" value={customerTypes.PARENT} onChange={onChange} />
            <Label name={customerTypes.PARENT} noMargin>
              Sou responsável pelo aluno
            </Label>
          </RadioContainer>
        </FormGroup>
      </Col>
      <Col lg={6} sm={12}>
        <FormGroup mb="20px">
          <RadioContainer>
            <Radio id={customerTypes.STUDENT} name="type" value={customerTypes.STUDENT} onChange={onChange} />
            <Label name={customerTypes.STUDENT} noMargin>
              Sou o próprio
            </Label>
          </RadioContainer>
        </FormGroup>
      </Col>
    </Row>
  );
};

ResponsibleRadio.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ResponsibleRadio;
